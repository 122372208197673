import React from "react";
import historyLogo from "./../../img/history.svg";
import { useAppContext } from "../../libs/contextLib";
const flattenObject = (obj) => {
  const flattened = {}

  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(flattened, flattenObject(obj[key]))
    } else {
      flattened[key] = obj[key]
    }
  })

  return flattened
}
export default function PinCard(props) {
  const { UUID, attributes,language,placeHistory } = useAppContext();
  if(props.sensor.pin){
  let pin = flattenObject(props.sensor.pin)
  let body = [];
  body.push(
  <tr>
    <td>{UUID} </td>
    <td>{pin.chassis}</td>
  </tr>)
    body.push(attributes.flatMap( (element) => {
      if(element.default){
        return (
          <tr>
          <td>{element[`string${language}`]}</td>
          <td>{pin[element.attribute]}</td>
        </tr>)
      }
      else{
        return [];
      }

    }))

  return (
    <>
      <h1>Kast Positie {props.sensor.place}</h1>
      <table class="table -minimal -small">
        {props.sensor.pin && Object.keys(props.sensor.pin).length !== 0 && (
          <>
          { body }
            <tr>
              <td>Status </td>
              <td>{props.sensor.pin.pinStatus}</td>
            </tr>
          </>
        )}
        <tr>
          <td>Parkeerplaats</td>
          <td>{props.sensor.pin.placeName}</td>
        </tr>
      </table>
      {placeHistory &&
            <span
            class="button -transparent -open-model"
            data-model="history"
            onClick={() => {
              props.getHistoryPlace(props.place);
            }}
          >
            <img src={historyLogo} alt="" />
            Historiek plaats bekijken
          </span>}

    </>
  );}
  else{
    return <></>

  }
}
