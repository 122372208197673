import React, {useState,useEffect, useRef} from "react";
import crossIcon from "./../img/icon-cross.svg";
import { useAppContext } from "./../libs/contextLib";
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015

import {
  readDataAWS,
getCarBrandsAWS,
getPopularCarBrandsAWS,
getBrandModelsAWS
} from "./../libs/lambdaLib";


export default function AddAssetForm(props) {

    const {
        company,
        username,
        attributes,
        UUID,
        language,
      } = useAppContext();

      const [tempAttributes,setTempAttributes] = useState({})
      const [tempUUID,setTempUUID] = useState("")
      const [formAttributes,setFormAttributes] = useState(<></>)
      const [plate,setPlate] = useState("")
      const [carBrands,setCarBrands] = useState([])
      const [selectedBrand,setSelectedBrand] = useState("")
      const [popularCarBrands,setPopularCarBrands] = useState([])
      const [carModels,setCarModels] = useState([])

    const  onChangeCheck = (attr,value) => {
        setTempAttributes((prev) => {
            const copy = {...prev};
            copy[attr] =  value;
            console.log(copy);
            return copy;
        })
    }

    useEffect(()=> {
      getBrands();
    },[])


    const getBrands = async () => {

      const data =   await getCarBrandsAWS()
      let brands = readDataAWS(data).payload.brands
      setCarBrands(brands.sort());
      await getPopularBrands();
    }

      const getPopularBrands = async () => {

        const data =   await getPopularCarBrandsAWS()
        let brands = readDataAWS(data).payload.brands
        setPopularCarBrands(brands.sort());

      }


      const getModels= async () => {

        try{
        const data =   await getBrandModelsAWS(selectedBrand)
        let models = readDataAWS(data).payload.models
        setCarModels(models.sort());}
        catch(e){


        }

      }



    useEffect(() => {
        let carBrandsCopy = [...carBrands]
      for (let i = popularCarBrands.length-1; i >=0; i--) {
        const element = popularCarBrands[i];
        carBrandsCopy.splice(carBrandsCopy.indexOf(element), 1);
        carBrandsCopy.unshift(element);
      }
      console.log(carBrandsCopy)
      setCarBrands(carBrandsCopy);

    },[popularCarBrands])

    useEffect(() => {
      console.log("brand");
      console.log(selectedBrand);
      if(selectedBrand && selectedBrand !== ""){
        getModels(selectedBrand);
      }

    },[selectedBrand])

  useEffect(() => {
    if(attributes){
        let buttons = [<></>];
        if(props.license){

        }
        else{   const uuidForm= (
          <div class={`form__control`}>
          <label for="email">{UUID}*</label>
          <input type="text" onChange={(e) => setTempUUID(e.target.value)} value={tempUUID} />
        </div>
      );
      buttons.push(uuidForm)
    }

        for (let i = 0; i < attributes.length; i++) {
          const element = attributes[i];
          if(element.default){
            if(element.attribute === "brand"){
              if(props.license){
                const button = (
                  <div class={`form__control`}>
                  <label for="email">{element.attribute}</label>
                  <input type="text" onChange={(e) => onChangeCheck(element.attribute,e.target.value)} value={tempAttributes[element.attribute]}/>

                </div>
                );
                buttons.push(button);
              }else{
                const typeahead = <div class={`form__control`}>
                <Typeahead
                              id="basic-typeahead-single"
                              labelKey="name"
                              options={carBrands}
                              placeholder="Merk"
                              paginationText="Laad meer"
                              maxResults={20}
                              onChange={(selected) => {
                                setSelectedBrand(selected[0])
                                onChangeCheck(element.attribute,selected[0])
                              }}
                              onInputChange={(selected) => {
                                onChangeCheck(element.attribute,selected)
                              }}
                                /></div>
           buttons.push(typeahead);
              }


            }
            else if(element.attribute === "model"){
              if(props.license){
                const button = (
                  <div class={`form__control`}>
                  <label for="email">{element.attribute}</label>
                  <input type="text" onChange={(e) => onChangeCheck(element.attribute,e.target.value)} value={tempAttributes[element.attribute]}/>

                </div>
                );
                buttons.push(button);
              }
              else{
                const typeahead =                       <div class={`form__control`}>
                <Typeahead
                                              id="basic-typeahead-single"
                                              labelKey="name"
                                              options={carModels}
                                              placeholder="Model"
                                              paginationText="Laad meer"
                                              maxResults={20}
                                              onChange={(selected) => {
                                                onChangeCheck(element.attribute,selected[0])
                                              }}
                                              onInputChange={(selected) => {
                                                onChangeCheck(element.attribute,selected)
                                              }}
                                              /></div>
               buttons.push(typeahead);
              }


                        }
                        else if(element.attribute === "color"){
            const button = (
                <div class={`form__control`}>
                <label for="email">{element.attribute}</label>
                <input type="text" onChange={(e) => onChangeCheck(element.attribute,e.target.value)} value={tempAttributes[element.attribute]}/>

              </div>
              );
              buttons.push(button);
            }
          }

        }
        setFormAttributes(buttons);
    }

  },[attributes,tempUUID,tempAttributes,carModels,carBrands])






  const handleClose = () => {
    setTempAttributes({});

    props.handleClose();
  }

 const searchPlate = async () => {
  fetch(`https://opendata.rdw.nl/resource/m9d7-ebf2.json?kenteken=${plate}`)
  .then((response) => response.json())
  .then((data) => {
     setTempUUID(data[0].kenteken);
     const item  = {brand: data[0].merk,
                    model : data[0].handelsbenaming,
                    color: data[0].eerste_kleur,
                    license:data[0].kenteken
     }
     item.brand =  item.brand[0].toUpperCase() +  item.brand.slice(1);
     item.model =  item.model[0].toUpperCase() +  item.model.slice(1);
     console.log(item)

     setTempAttributes(item);
  })
  .catch((err) => {
     console.log(err.message);
  });

 }

  return (
    <>
      <div className={`model ${props.show ? "-active" : ""}`}>
        <div class="model__container">
          <h1>Manueel toevoegen</h1>
          <div class={`form__control`}>
{/*
          <Typeahead
          id="basic-typeahead-single"
          labelKey="name"
          options={carBrands}
          placeholder="Merk"
          paginationText="Laad meer"
          maxResults={20}
        /> */}
  {props.license?     <>       <label>Kenteken</label>
            <input type="text" onChange={(e) => setPlate(e.target.value)} />
          <span
              class="button"
             onClick={ () => {searchPlate()}}
            > Kenteken zoeken</span></>:<></>}
          </div>

  {formAttributes}
          <img
            src={crossIcon}
            alt=""
            class="model__cross"
            onClick={handleClose}
          />
                            <span
              class="button -wide"
             onClick={ () => {props.postManualAsset(tempUUID,tempAttributes)}}
            > Indienen</span>
            </div>
        </div>
    </>
  );
}
