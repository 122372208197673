import React, { useEffect} from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";

export default function AuthenticatedRoute({ children, ...rest }) {
  const { pathname, search } = useLocation();
  const { isAuthenticated,mobileUnauth } = useAppContext();


  useEffect(() => {
    console.log("AUTH PATHNAME");
    console.log(pathname);
  }, [])

  const pinId = (pathname) => {
  return /\d/.test(pathname); // Checks if there's at least one digit in the string
  }

  return (

    <Route {...rest}>
      {isAuthenticated ? (
        children
      ) : ( pinId(pathname)?
      <Redirect to={
        `/u${pathname}`
      } />:
        <Redirect to={
          `/login?redirect=${pathname}${search}`
        } />
      )}
    </Route>
  );
}
