import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { ListGroup, Col, Row, Button, Modal } from "react-bootstrap";
import EventFlow from "./EventsFlow";
import crossIcon from "./../img/icon-cross.svg";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import { useAppContext } from "../libs/contextLib";
import crossLogo from "./../img/icon-cross.svg";
import historyIcon from "./../img/history.svg";
import {  readDataAWS,getStatesAWS,changePlaceAWS,  getChangePlaceSettingsAWS,
  checkPlaceOccupiedAWS, getZonesAWS, getEventsAWS, deletePinAWS } from "../libs/lambdaLib";
import { useHistory } from "react-router-dom";
import {ToggleButtonGroup, ToggleButton} from "react-bootstrap";

export const flattenObject = (obj) => {
  const flattened = {}

  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(flattened, flattenObject(obj[key]))
    } else {
      flattened[key] = obj[key]
    }
  })

  return flattened
}

export default function PinViewUnAuth() {

  const history = useHistory();


  const { id } = useParams();
  const [isError,setIsError] = useState(false);
  const [errorText,setErrorText] = useState("");
  const [pinInfo, setPinInfo] = useState(null);
  const [moreInfo, setMoreInfo] = useState(false);
  const { company, username,UUID,attributes} = useAppContext();



  useEffect( () => {
    onLoad()
  }, []);

  async function onLoad(){
    console.log("TRYING")
    try {
      const result = await getPin();
      setIsError(false);

      console.log(result);
      //   const result = await axios(
      //    `https://r14ui4y5z1.execute-api.eu-central-1.amazonaws.com/dynamoDB/pin/${id}`,
      // result.payLoad.chassis = result.payLoad.pk;
      // delete result.payLoad.pk;
      setPinInfo(flattenObject(result.payLoad));
      console.log("GOT PIN");
      console.log(result.payLoad);
    } catch (e) {
      setIsError(true);
      console.log(e.toString());
      if(e.toString().includes("500")){
        setErrorText("Pin niet toegekend");
        history.push(`/login?redirect=assign/${id}`);
      }
      if(e.toString().includes("406")){
        setErrorText("Pin niet toegekend");
        history.push(`/login`);
      }
      else{
        setErrorText("Ongeldige Pin/Object")
      }
    }
  }

  const ListPlot = () => {
    if (pinInfo) {
      if (!moreInfo) {
        return (
          <>
            <h1>{company === "Leseman"?pinInfo.merk:pinInfo.brand}</h1>
            <h6>{`${pinInfo.model} ${pinInfo.type?pinInfo.type:""}`}</h6>

            <div class="blocks">
              <div class="block">
                <span class="block__title">Plaats</span>
                <span class="block__value">{pinInfo.placeName}</span>
              </div>
              <div class="block">
                <span class="block__title">Status</span>
                <span class="block__value">{pinInfo.pinStatus}</span>
              </div>
              <div class="block -wide">
                <span class="block__title">{UUID?UUID:"ID"}</span>
                <span class="block__value">{pinInfo.chassis}</span>
                {/* {            <span
              class="button -tertiary"
              data-model="history"
              onClick={onClickHistory}
            >
              <img src={historyIcon} alt="" />
            </span>} */}
              </div>
            </div>
          </>
        );
      } else {
        let info = [];
        for (const property in pinInfo) {
          info.push(
            <tr>
              <td>{property}</td>
              <td>{pinInfo[property]}</td>
            </tr>
          );
        }
        return (
          <>
            <h1>{company === "Leseman"?pinInfo.merk:pinInfo.brand}</h1>
            <h6>{`${pinInfo.model} ${pinInfo.type}`}</h6>
            <table className="table">
              <tbody>{info}</tbody>
            </table>
          </>
        );
      }
    } else {
      return <p>Loading</p>;
    }
  };

  const getPin = async () => {
    let pos = { latitude: 0, longitude: 0 };
    try {
      //pos = await getLocation();
      console.log(pos);
    } catch (e) {
      pos = { latitude: 0, longitude: 0 };
      console.log(e);
    } finally {
      console.log(pos);
      return API.get("pinAPI", `/pin/${id}`, {
        queryStringParameters: {
          lat: pos.latitude,
          long: pos.longitude,
          company,
          username,
        },
      });
    }
  };


  const getLocation = () => {
    return new Promise((resolve, reject) => {
      console.log(navigator.geolocation);
      if (navigator.geolocation) {
        console.log(navigator.geolocation);

        navigator.geolocation.getCurrentPosition(
          (pos) => {
            console.log(pos);
            resolve(pos.coords);
          },
          (e) => {
            reject("Timeout");
          },
          { timeout: 10000 }
        );
      } else {
        reject("Geolocation is not supported by this browser.");
      }
    });
  };

  const moreInfoButton = () => {
    setMoreInfo(!moreInfo);
  };





  return (
    <>
      <div class="container">
        {isError &&
        <h1>{errorText}</h1>}
        {!isError && ListPlot()}
      </div>
    </>
  );
}
