import React, { useState, useEffect } from "react";
import {
  getCabinetsCompanyAWS,
  readDataAWS,
  getPinsCabinetAWS,
} from "../../libs/lambdaLib";
import { useAppContext } from "../../libs/contextLib";
import ButtonGrid from "./ButtonGrid";


const VIEW =  {
  OVERVIEW: 0,
  CABINETGRID: 1,
  CABINETTASK: 2
 }

export default function Cabinet() {
  const [cabinetList, setCabinetList] = useState([]);
  const [view, setView] = useState(VIEW.OVERVIEW);
  const [rows, setRows] = useState(false);
  const [columns, setColumns] = useState(false);
  const [activeCabinet, setActiveCabinet] = useState({});

  const { company } = useAppContext();
  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    const result = await getCabinetsCompanyAWS(company);
    const { statusCode, payload, message } = readDataAWS(result);
    console.log("cabinets");
    console.log(payload);
    const cabinets = payload.map((cabinet) => {
      cabinet.stringKey = cabinet.sk.substr(cabinet.sk.indexOf("#") + 1);
      return cabinet;
    });

    if (cabinets.length === 1) {
      console.log(cabinets[0]);
      clickCabinet(cabinets[0]);
    } else {
      setView(VIEW.OVERVIEW);
      setCabinetList(cabinets);
    }
  };

  const clickCabinet = async (cabinet) => {
    const result = await getPinsCabinetAWS(cabinet.stringKey, company);
    console.log(result);
    const { statusCode, payload, message } = readDataAWS(result);
    console.log(payload);
    payload.sort((a, b) => {
      let posa = parseInt(a.sk.substr(a.sk.indexOf("#") + 1));
      let posb = parseInt(b.sk.substr(b.sk.indexOf("#") + 1));
      return posa - posb;
    });
    setRows(cabinet.rows);
    setColumns(cabinet.columns);
    setCabinetList(payload);
    setActiveCabinet(cabinet);
    setView(VIEW.CABINETGRID);
  };


  const clickTask = async (cabinet) => {
    const result = await getPinsCabinetAWS(cabinet.stringKey, company);
    console.log(result);
    const { statusCode, payload, message } = readDataAWS(result);
    let list = payload.filter((cabinetplace) => {
      return Object.keys(cabinetplace.pin).length > 0
    });
    list.sort((a,b) => {
      return -a.lastUpdate.localeCompare(b.lastUpdate);
    })
    console.log(list);

    setRows(cabinet.rows);
    setColumns(cabinet.columns);
    setCabinetList(list);
    setActiveCabinet(cabinet);
    setView(VIEW.CABINETTASK);
  };


  const cabinetCard = () => {
    let result = [];
    result.push(
      cabinetList.map((cabinet) => {
        return (
          <div class="overview__item">
            <h1 class="overview__title">{cabinet.placeName}</h1>
            <p>
              {cabinet.city}
              <br />
              {/* {cabinet.placeName} */}
            </p>
            <a
              class="button"
              onClick={() => {
                clickCabinet(cabinet);
              }}
            >
              Open kast
            </a>
            {/* { company === "CoDek" &&
            <a
              class="button -small"
              onClick={() => {
                clickTask(cabinet);
              }}
            >
              Taaklijst
            </a>} */}
            {/* <div class="overview__time">
              1m geleden
              <br />
              <span class="small">geleden</span>
            </div> */}
          </div>
        );
      })
    );
    return <div className="overview">{result}</div>;
  };

  return (
    <section>
      {view === VIEW.OVERVIEW && cabinetCard()}
      {view === VIEW.CABINETGRID &&
      <ButtonGrid
          cabinetKey={activeCabinet}
          cabinetList={cabinetList}
          rows={rows}
          columns={columns}
        />}
      {/* {view === VIEW.CABINETTASK &&
      <TaskList
          cabinetKey={activeCabinet}
          cabinetList={cabinetList}
          rows={rows}
          columns={columns}
        />} */}
    </section>
  );
}
