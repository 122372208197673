import React, { useEffect, useState, useRef, useCallback  } from "react";
import { API } from "aws-amplify";
import { ListGroup, Col, Row, Button, Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import AddAssetForm from "./AddAssetForm";
import { Auth } from "aws-amplify";
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015

import Webcam from "react-webcam";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera,faKeyboard  } from "@fortawesome/free-solid-svg-icons";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import { useAppContext } from "../libs/contextLib";
import crossLogo from "./../img/icon-cross.svg";
import {
  getCompanyPinsAWS,
  insertPinDBAWS,
  getCarBrandsAWS,
  getNewAssetsAWS,
  readDataAWS,
  addManualAssetAWS,
  getAssetWittebrug,
  getPopularCarBrandsAWS

} from "./../libs/lambdaLib";
import { useHistory } from "react-router-dom";
import PageVisibility from 'react-page-visibility';

const AWS = require('aws-sdk');
let rekog;

let columns = [
  {
    dataField: "chassis",
    sort: true,
    text: "Chassis",
  },
  {
    dataField: "assetstring",
    text: "Auto",
    sort: true,
  }
];


export const flattenObject = (obj) => {
  const flattened = {}

  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(flattened, flattenObject(obj[key]))
    } else {
      flattened[key] = obj[key]
    }
  })

  return flattened
}

export default function CompanyPinList() {

  const history = useHistory();


  const { id } = useParams();

  const { company, username,UUID,attributes,assetSummary} = useAppContext();
  const [pinList, setPinList] = useState([]);
  const [manualAddShow, isManualAddShow] = useState(false);
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const [imgSrcRaw, setImgSrcRaw] = useState(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [point, setPoint] = useState(null);
  const [showVIN, setShowVIN] = useState(false);
  const [detectText, setDetectText] = useState("");
  const [canvasSize, setCanvasSize] = useState({width: 480, height: 640});
  const [camVisible, setCamVisible] = useState(false);
  const [lastVINDigits, setLastVINDigits] = useState(null);
  const [carBrands,setCarBrands] = useState([])
  const [selectedBrand,setSelectedBrand] = useState(null)
  const [popularCarBrands,setPopularCarBrands] = useState([])
  const [VINType,setVINType] = useState(false);

  const capture = useCallback(
    () => {
      const data = webcamRef.current.getScreenshot();
      setImgSrcRaw(data);
      console.log("camdata");
      const canvas = webcamRef.current.getCanvas();
      const context = canvas.getContext('2d');
      console.log(canvas.width)
      setCanvasSize({width:canvas.width, height: canvas.height})
      context.clearRect(0, 0, canvas.width, canvas.height);
      console.log(data)
      let image;
          image = atob(data.split("data:image/png;base64,")[1]);

      // Unencode image bytes for Rekognition DetectFaces API.
      const length = image.length;
      const imageBytes = new ArrayBuffer(length);
      const ua = new Uint8Array(imageBytes);
      for (var i = 0; i < length; i++) {
        ua[i] = image.charCodeAt(i);
      }
      setImgSrc(ua);

      },
    [webcamRef]
  );

  useEffect(() =>{
    if(imgSrc){

      const context = canvasRef.current.getContext("2d");


      var blob = new Blob([imgSrc], { type: "image/png" });
      var url = URL.createObjectURL(blob);
      var img = new Image();
      const width = canvasSize.width;
      const height = canvasSize.height;
      img.onload = function(){
        context.drawImage(img, 0, 0)
        // if(isBarcode){
        //   codeReader.decodeFromImage(undefined, imgSrcRaw).then(result => {
        //     console.log(result)
        //     setBarcode(result)
        //   }).catch((err) => {
        //     console.log(err)

        //   })
        // }
       // else {
          //This is where AWS gets called so rekog is he aws object.
        const params = {Image: {Bytes: imgSrc}}
        rekog.detectText(params, function(err, response) {
          if (err) {
            console.log(err, err.stack); // handle error if an error occurred
          } else {
            console.log(`Detected Text for`);
            console.log(response.TextDetections);
            let correctVINObject;
            let sanitizedStringVin;
            let lastFiveDigits;

            for (let index = 0; index < response.TextDetections.length; index++)
            {
              const element = response.TextDetections[index];
              //We need to identify WHAT a VIN is:
              //VIN is  17 characters  in length
              //AWS provides type LINE or WORD in the array. for VIN we should use WORD type
              if(element.Type != "WORD"){continue;}

              //Sometimes VIN numbers have * * too close  to the VIN, making the length 19. This step ensures that we check if there is a * at the beginning and/or end of the string and removes it.

              if(element.DetectedText.length < 17 ){continue;}

              let tempVinString = element.DetectedText;
              if(tempVinString.length == 19)
              {
                console.log("VIN is 19 in length, trying to slice first and last string character.");
                console.log("Before: " + tempVinString);
                tempVinString = tempVinString.slice(1);
                tempVinString = tempVinString.slice(0, -1);
                console.log("After: " + tempVinString);
              }

              let lastCharacter = tempVinString.charAt(tempVinString.length - 1);
              let lastCharIsNum = /^\d+$/.test(lastCharacter);
              if(!lastCharIsNum)
              {
                tempVinString = tempVinString.slice(0, -1);
              }
              if(tempVinString.length != 17){continue;}

              //At this point we are certain the string is 17 characters, we don't know if this is actually the vin but the next step could "sanitize" it.
              //console.log(cleanedVinString);

              let last5 = tempVinString.slice(-5);
              let isnum = /^\d+$/.test(last5);
              console.log("isnum: " + isnum);

              if(!isnum){continue;}

              lastFiveDigits = last5;
              console.log("Last 5 digits: " + last5);
              //Ideally we would ask the user if the VIN is correct or not, or ask if the last 5 are correct.
              //if user says yes then good send request to DB, otherwise take photo again.
              //It's a higher chance of the last 5 being right than the entire VIN to be right, we only need the last 5 characters anyways.
              //this could be set in the function that requests the lookup in the DB

              //If we get here the string has: 17 characters and the last 5 are numerical.
              console.log(tempVinString);
              console.log(element);
              sanitizedStringVin = tempVinString;
              correctVINObject = element;

              correctVINObject.lastFiveDigits = last5;
              setLastVINDigits(last5);
              correctVINObject.viewOnlyText = sanitizedStringVin;
              break;
            }

            if(correctVINObject == undefined){
              setDetectText("Geen VIN gevonden")
              setPoint(null);
              setShowVIN(true);
            }
            else{
              //correctVINObject.DetectedText = sanitizedStringVin + " | Laatste 5 cijfers: " + lastFiveDigits;
              setPoint(correctVINObject);
              setShowVIN(true);
            }

            //setPoint(response.TextDetections[0]);
            // response.TextDetections.forEach(label => {
            //   console.log(`Detected Text: ${label.DetectedText}`),
            //   console.log(`Type: ${label.Type}`),
            //   console.log(`ID: ${label.Id}`),
            //   console.log(`Parent ID: ${label.ParentId}`),
            //   console.log(`Confidence: ${label.Confidence}`),
            //   console.log(`Polygon: `)
            //   console.log(label.Geometry.Polygon)
            // }
            // )
          }
      })
        //}

      //         context.beginPath();
      // context.rect(0.1*width, 0.1*height, 0.8*width, 0.8*height);

      // context.lineWidth = 7;
      // context.strokeStyle = 'black';
      // context.stroke();
      }

      img.src = URL.createObjectURL(blob);

}},[imgSrc])


useEffect(() => {
  if(point){
    const context = canvasRef.current.getContext("2d");
    context.beginPath();
   const width = canvasSize.width;
   const height = canvasSize.height;
   console.log(width);
    // let lowestPoint = {X:,Y};
    // let highestPoint;
    point.Geometry.Polygon.forEach((point,i) => {
      if(i===0){
        context.moveTo((point.X)*width,point.Y*height);

      }
      else {
        context.lineTo((point.X)*width,point.Y*height);
      }
    })
            context.lineTo((point.Geometry.Polygon[0].X)*width,point.Geometry.Polygon[0].Y*height);
            context.lineWidth = 1;

            context.strokeStyle = 'red';
        context.font = "20px Arial";
        context.fillStyle = "red";

            context.fillText(point.DetectedText,
               point.Geometry.BoundingBox.Left*width,
               (point.Geometry.BoundingBox.Top+point.Geometry.BoundingBox.Height < 0.8?point.Geometry.BoundingBox.Top+point.Geometry.BoundingBox.Height+0.1:point.Geometry.BoundingBox.Top-0.1)*height);
            context.stroke();


            setDetectText(point.viewOnlyText + " | Laatste 5 cijfers: " + point.lastFiveDigits)


  }
},[point])

  useEffect(() => {
    getPins();
    loadRecog();
    getBrands();
  }, []);

  useEffect(() => {
  }, [selectedBrand]);

  useEffect(() => {
    //getNewPins();
    //loadRecog();
    if(lastVINDigits && lastVINDigits.length == 5){
      searchAPIWittebrugPins(lastVINDigits)
    }
  }, [lastVINDigits]);
  
  useEffect(() => {
    let carBrandsCopy = [...carBrands]
  for (let i = popularCarBrands.length-1; i >=0; i--) {
    const element = popularCarBrands[i];
    carBrandsCopy.splice(carBrandsCopy.indexOf(element), 1);
    carBrandsCopy.unshift(element);
  }
  console.log(carBrandsCopy)
  setCarBrands(carBrandsCopy);

},[popularCarBrands])


  const loadRecog = async () => {
    const credentials = await Auth.currentCredentials();
        console.log(credentials);
        rekog = new AWS.Rekognition({
          region: "eu-central-1",
          credentials,
        });
  }

  const getBrands = async () => {

    const data =   await getCarBrandsAWS()
    let brands = readDataAWS(data).payload.brands
    setCarBrands(brands.sort());
    getPopularBrands();
  }

  const getPopularBrands = async () => {

    const data =   await getPopularCarBrandsAWS()
    let brands = readDataAWS(data).payload.brands
    setPopularCarBrands(brands.sort());

  }

  const searchAPIWittebrugPins = async () => {

    const data =   await getAssetWittebrug(lastVINDigits,selectedBrand)
    let newCars = readDataAWS(data).payload;

    for (let i = 0; i < newCars.length; i++) {
      newCars[i] = flattenObject(newCars[i]);
      newCars[i].assetstring = "";
      if(newCars[i].attributes){
        for (let i = 0; i < assetSummary.length; i++) {
          newCars[i].assetstring = `${newCars[i].assetstring} ${newCars[i].attributes[assetSummary[i]]}`;
        }
      }
      else newCars[i].assetstring = `${newCars[i].brand} ${newCars[i].model} ${newCars[i].type}`;
    }

    console.log(newCars)
    setPinList(newCars);

  }

  const getPins = async () => {

    const data =   await getCompanyPinsAWS(company)
    let newCars = readDataAWS(data).payload;

    for (let i = 0; i < newCars.length; i++) {
      newCars[i] = flattenObject(newCars[i]);
      newCars[i].assetstring = "";
      if(newCars[i].attributes){
        for (let i = 0; i < assetSummary.length; i++) {
          newCars[i].assetstring = `${newCars[i].assetstring} ${newCars[i].attributes[assetSummary[i]]}`;
        }
      }
      else newCars[i].assetstring = `${newCars[i].brand} ${newCars[i].model} ${newCars[i].type?newCars[i].type:""}`;
    }

    console.log(newCars)
    setPinList(newCars);

  }



  const MySearch = (props) => {
    const handleChange = (input) => {
      console.log(input.target.value);
      props.onSearch(input.target.value);
    };
    useEffect(() => {
      if(props.value){
      props.onSearch(props.value);
      }
      else{
        props.onSearch("");
      }
    }, [props.value]);
    return (
      <input
        type="text"
        className="search"
        placeholder={props.value? props.value: "Zoeken"}
        onChange={handleChange}
      />
    );
  }


  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      console.log(row.pinID)
     history.push(`/${row.pinID}`);

            //onClickRow(row, rowIndex);

    },
  };

  const mystyleright = {
    float: "right"
  };

  const mystyleleft = {
    float: "left"
  };

  const videoConstraints = {
    facingMode: { ideal: "environment" }
  };
  return (
    <>
    <div className={`model ${showVIN ? "-active" : ""}`}>
        <div class="model__container">
          <h1>VIN</h1>
  <h3>{detectText}</h3>

          {point && <><span
              class="button -wide"
             onClick={ () => {   setShowVIN(false);
              setImgSrc(null);
              setCamVisible(false)
              searchAPIWittebrugPins()}

  }
            > Laatste Cijfers OK</span>
            <div class={`form__control`}>
            <Typeahead
                          id="basic-typeahead-single"
                          labelKey="name"
                          options={carBrands}
                          placeholder="Merk"
                          paginationText="Laad meer"
                          maxResults={20}
                          onChange={(selected) => {
                            setSelectedBrand(selected[0])
                          }}

                            /></div></>}
               <span
              class="button -wide -sec"
             onClick={ () => {
               setLastVINDigits(null)
               setShowVIN(false)
               setImgSrc(null);
               setCamVisible(true);}
              }
            > Opnieuw</span>
                    <span
              class="button -wide -cancel"
             onClick={ () => {
              setLastVINDigits(null);
               setShowVIN(false);
               setImgSrc(null);
               setCamVisible(false);}}
            > Annuleer</span>
        </div>
        </div>
        <div className={`model ${VINType ? "-active" : ""}`}>
        <div class="model__container">
          <h1>VIN</h1>
          <div class={`form__control`}>
                  <label for="email">Chassis</label>
                  <input type="number" onChange={(e) => setLastVINDigits(e.target.value)} value={lastVINDigits}/>

                </div>
            <div class={`form__control`}>
            <Typeahead
                          id="basic-typeahead-single"
                          labelKey="name"
                          options={carBrands}
                          placeholder="Merk"
                          paginationText="Laad meer"
                          maxResults={20}
                          onChange={(selected) => {
                            setSelectedBrand(selected[0])
                          }}

                            /></div>
                            <span
              class="button -wide"
             onClick={ () => {      setVINType(false);;

              searchAPIWittebrugPins()}

  }
            > Laatste Cijfers OK</span>
                    <span
              class="button -wide -cancel"
             onClick={ () => {
              setLastVINDigits(null);
               setVINType(false);
          }}
            > Annuleer</span>
        </div>
        </div>

    {imgSrc && <div >   <canvas
            ref={canvasRef}
            width={canvasSize.width} height={canvasSize.height}
  /></div>}
           {camVisible && <div hidden={imgSrc}> <Webcam
         videoConstraints={videoConstraints}
         screenshotFormat="image/png"
         onClick={capture}
         ref={webcamRef}/>
         </div>}
      <div class="container">
        <ToolkitProvider
        keyField="id"
        data={pinList}
        columns={columns}
        search
      >
        {(tableProps) => (
          <div>
            <MySearch class="part-12" {...tableProps.searchProps} value={lastVINDigits?lastVINDigits:null} />
            <div class="part-12" style={mystyleright}>
            <div class="part-12" style={mystyleright}>
                                <label class="file">
                        <span
              class="button"
              onClick={() =>{setCamVisible(!camVisible)}}
            ><FontAwesomeIcon icon={faCamera }/> VIN scan</span>
                              <span
              class="button"
              onClick={() =>{setVINType(true)}}
            ><FontAwesomeIcon icon={faKeyboard }/> VIN type</span>
            </label>
              </div>
              </div>
            <BootstrapTable
              classes="table -clickable"
              rowClasses="-open-model"
              bootstrap4={true}
              keyField="lastUpdate"
              hover
               rowEvents={rowEvents}
              // rowStyle={rowStyle2}
              {...tableProps.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
      </div>
    </>
  );
}
