import React, { useEffect} from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";

function querystring(name, url = window.location.href) {
  name = name.replace(/[[]]/g, "\\$&");

  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
  const results = regex.exec(url);

  if (!results) {
    return null;
  }
  if (!results[2]) {
    return "";
  }

  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export default function UnauthenticatedRoute({ children, ...rest }) {
  const { isAuthenticated,mobileUnauth } = useAppContext();
  const redirect = querystring("redirect");
  const { pathname, search } = useLocation();

  useEffect(() => {
    console.log("UNAUTH")

    console.log(pathname)
  }, [])
  return (
    <Route {...rest}>
      {!isAuthenticated? (
        children
      ) : (
        <Redirect to={redirect === "" || redirect === null ? "/login?redirect=/" : redirect} />
      )}

    </Route>
  );
}
