import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { ListGroup, Col, Row, Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode} from "@fortawesome/free-solid-svg-icons";
import EventFlow from "./EventsFlow";
import crossIcon from "./../img/icon-cross.svg";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import { useAppContext } from "../libs/contextLib";
import crossLogo from "./../img/icon-cross.svg";
import historyIcon from "./../img/history.svg";
import {  readDataAWS,getStatesAWS,changePlaceAWS,  getChangePlaceSettingsAWS,
  checkPlaceOccupiedAWS, getZonesAWS, getEventsAWS, deletePinAWS } from "../libs/lambdaLib";
import { useHistory } from "react-router-dom";
import {ToggleButtonGroup, ToggleButton} from "react-bootstrap";
import QRCodeSVG from "qrcode.react"

export const flattenObject = (obj) => {
  const flattened = {}

  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(flattened, flattenObject(obj[key]))
    } else {
      flattened[key] = obj[key]
    }
  })

  return flattened
}

export default function PinView() {

  const history = useHistory();


  const { id } = useParams();

  const [buttonStates, setButtonStates] = useState(["Showroom", "Werkplaats", "Carwash"]);
  const [isError,setIsError] = useState(false);
  const [errorText,setErrorText] = useState("");
  const [defaultButtonStates, setDefaultButtonStates] = useState("");
  const [pinInfo, setPinInfo] = useState(null);
  const [moreInfo, setMoreInfo] = useState(false);
  const [show, setShow] = useState(false);
  const [showChangePlace, setShowChangePlace] = useState(false);
  const { company, username,UUID,attributes,hasQRCode,printer,labelInfo} = useAppContext();
  const [newPlace, setNewPlace] = useState("0");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [eventList, setEventList] = useState([]);
  const [showEvents, setShowEvents] = useState(false);

  const [QRArray,setQRArray] = useState([]);

  const [showQR, setShowQR] = useState(false);
  const [labelQR, setLabelQR] = useState(false);
  const [QRString, setQRString] = useState("");

  const [zones, setZones] = useState([]);
  const [dropdownClass, setDrowndownClass] = useState("dropdown__assign");

  const [currentParking,setCurrentParking] = useState(0);
  const [newplaceFormatter,setNewplaceFormatter] = useState([
    {
    inputType: "number",
    inputMax: 60,
    inputMin: 1,
    inputFormatter: [],
    validation: [{action:"LENGTH",value: 1,text: "2 cijfers"},
    {action:"between",pos: 0,text: "Tussen 1-100 ",values:[["1","100"]]}]
      }]);
    const [placeValidationText, setPlaceValidationText] = useState([]);
    const [placeWarningText, setPlaceWarningText] = useState([]);
    const [canSubmitNewPlace,setCanSubmitNewPlace] = useState(true);
    const [togglePlace,setTogglePlace] = useState({});

  const handleShowChangePlace = () => setShowChangePlace(true);


  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad(){
    try {
      await getStates() ;

      const result = await getPin();
      getChangePlaceSettings();
      setIsError(false);
      getZones();



      console.log(result);
      //   const result = await axios(
      //    `https://r14ui4y5z1.execute-api.eu-central-1.amazonaws.com/dynamoDB/pin/${id}`,
      // result.payLoad.chassis = result.payLoad.pk;
      // delete result.payLoad.pk;
      setPinInfo(flattenObject(result.payLoad));

      console.log(result.payLoad);
    } catch (e) {
      setIsError(true);
      console.log(e.toString());
      if(e.toString().includes("500")){
        setErrorText("Pin niet toegekend");
        console.log("Pin niet toegekend");
        history.push(`/assign/${id}`);
      }
      else{
        setErrorText("Ongeldige Pin/Object")
      }
    }
  }

  useEffect(() => {

    if(QRArray && QRArray.length > 0){

      setQRString(generateQRcode(QRArray));

    }
  },[labelQR]);

  useEffect(() => {

    if(QRArray && QRArray.length > 0){

      setQRString(generateQRcode(QRArray));
      console.log("printer type")
      console.log(printer.type);
      if(printer.type && printer.type.length > 0){
        if(printer.type.length > 1){
          setLabelQR(true);
      }
      else if(printer.type.includes("label")){
        setLabelQR(true);

      }
      else{
        console.log("we here")

        setLabelQR(false);

      }
    }
    }
  },[QRArray]);


  useEffect(() => {
    onLoadPinInfo(pinInfo)
    if(hasQRCode && pinInfo){
      setQRArray(generateQRarray(pinInfo));

    }
  },[pinInfo])

async function onLoadPinInfo(pinInfo){
  if(pinInfo){

    if(pinInfo.pinStatus === "Sold" || pinInfo.pinStatus === "Delete"){
      setButtonStates([]);
    }
    else{
      setButtonStates(buttonMapping(defaultButtonStates));
    }
}
}


  useEffect( () => {
    console.log("togglePlace")
    console.log(togglePlace)
    let keys = Object.keys(togglePlace);
    const placeFormatter = newplaceFormatter[currentParking];

    if( keys.length === placeFormatter.toggles ){
      setCanSubmitNewPlace(true);
      setPlaceValidationText("");
      let stringT = "";
      for(let i = 0; i < keys.length; i++){

        if(i==0){
          stringT = stringT +   togglePlace[placeFormatter.toggleGroups[i].title];

        }
        else{
          stringT = stringT + " " +    togglePlace[placeFormatter.toggleGroups[i].title];

        }      }
      setNewPlace(stringT);
    }
    else{
      setCanSubmitNewPlace(false);
      setPlaceValidationText("Maak een keuze voor elke waarde");
    }

  },[togglePlace])

useEffect( () => {
  async function newplaceAsyncFunction() {

  const placeFormatter = newplaceFormatter[currentParking];

  for(let i=0;i<placeFormatter.inputFormatter.length;i++){
    const el = placeFormatter.inputFormatter[i];
    if(el.action === "ADD"){

      if(newPlace.length > 1 && newPlace.split(el.substring).length === 1){

        setNewPlace(newPlace.slice(0,el.pos).concat(el.substring).concat(newPlace.slice(el.pos)) )
      }
    }
    else if(el.action === "MAX_LENGTH"){
      if(newPlace.length > el.value){
        setNewPlace(newPlace.slice(0, el.value))
      }
    }

  }

  let text =[];
  if (placeFormatter.inputType === "number" && newPlace.charAt(0) === "0"){
    setNewPlace(newPlace.substring(1));
  }
  for(let i=0;i<placeFormatter.validation.length;i++){
    const el = placeFormatter.validation[i];
    let condi = false;
    switch (el.action) {
      case "LENGTH" :
        condi = newPlace.length >= el.value
        break;
      case "starts" :
        for(let j=0;j < el.values.length; j++){
          if(newPlace.startsWith(el.values[j])){
            condi=true;
          }
        }
        break;
      case "between" :
        for(let j=0;j < el.values.length; j++){
          let cmp = el.values[j];
          if(placeFormatter.inputType === "string" && newPlace >= cmp[0] && newPlace <= cmp[1]){
            condi=true;
          }
          else if(placeFormatter.inputType === "number" && newPlace >= Number(cmp[0]) && newPlace <= Number(cmp[1])){
            condi=true;
          }
        }
        break;
      default:
        break;
    }
    if(!condi){
    text.push(<><p>{el.text}</p></>);
    }
  }


  let parkingName;
  if(newplaceFormatter[currentParking].parking){
    parkingName = newplaceFormatter[currentParking].parking;

    console.log(pinInfo);

  }
  let data = await checkPlaceOccupiedAWS(company,newPlace,parkingName);
  const { payload } = readDataAWS(data);
  console.log(payload)
  if(payload  && payload.length && payload.length > 0){
    setPlaceWarningText(<><p>Plaats bezet door andere wagen</p></>);

  }
  else{
    setPlaceWarningText("");
  }

  if(placeFormatter.inputType !== "toggleGroup"){
    setCanSubmitNewPlace(text.length===0);
    setPlaceValidationText(text);
  }
}
 newplaceAsyncFunction();
},[newPlace])

const generateQRarray = (data) => {
  let array = [];
  console.log("labelInfo")
  console.log(labelInfo)
  for (let i = 0; i < labelInfo.length; i++) {
    const element = labelInfo[i];
    console.log(element);
    if(element.attribute){
      if(data[element.attribute]){
        if(element.action){
          if(element.action.function === "substring"){
            array.push(data[element.attribute].substring(0,10));
          }
        }
        else{
          array.push(data[element.attribute]);
        }
      }
      else{
        array.push("-");
      }
    }
    else if(element.UUID){
      array.push(data.chassis);
    }
}
console.log(array);
return array;
}

const generateQRcode = (array) => {
  let text = "START"
  if(!labelQR && printer.autodialog)  text=`${text}\nCodek`

  for (let i = 0; i < array.length; i++) {
    text=`${text}\n${array[i]}`
}
if(labelQR)  text=`${text}\nlabel`
else   text=`${text}\npols`
if(id){
   text=`${text}\nhttps://mobile.codek.be/#/${id}`}
else{
    text=`${text}\nhttps://www.codek.pro/`
 }
 text=`${text}\nEND`;
 //text=`${text}\n${array[i]}`;
return text;
}

const getEventsPin = async (chassis) => {
  const result = await getEventsAWS(chassis, company);
  const { payload } = readDataAWS(result);
  console.log(payload);

  setEventList(payload);
};

const zoneMapping = (array) => {
  return array.map((state) => {
    let item = {
      title: state.name,
      buttonClass: state.buttonClass,
      parkingName: state.parkingName,
    };
    if (state.action === "ZONE") {
      item.callback = () => {
        handleChangePlace(state.name,state.parkingName);
      };
    }
    return item;
  });
};


const handleEventsClose = () => {
  setShowEvents(false);
};



  async function  getChangePlaceSettings(){
    try{
      let data = await getChangePlaceSettingsAWS(company);
      const { payload } = readDataAWS(data);
      setNewplaceFormatter(payload.data.default);
    }
    catch(e){

    }
  };

  async function getStates() {
    let data = await getStatesAWS(company);
    const { payload } = readDataAWS(data);
    setDefaultButtonStates(payload.data);
    setButtonStates(buttonMapping(payload.data));
    console.log(payload.data);
  }


  const onClickHistory = () => {
    getEventsPin(pinInfo.chassis);
    setShowEvents(true);
  };

  async function getZones() {
    let data = await getZonesAWS(company);
    const { payload } = readDataAWS(data);
    if(payload){
    setZones(zoneMapping(payload.data));
    console.log("GetZones");
    console.log(payload.data);
    }

  }

  const buttonMapping = (array) => {
    if(array){
    console.log(array);
    return array.map((state) => {

      let item = {
        title: state.name,
        buttonClass: state.buttonClass,
      };
      console.log(state.action)
      console.log(item)
      if (state.action === "CHANGE_STATE") {
        item.callBack = () => {
          handleStateChange(state.state);
        };
      }
      else if (state.action === "DELETE") {
        item = {
          title: state.name,
          buttonClass: state.buttonClass,
          callback:
            handleDelete,
          action: state.action
        };
      }else if (state.action === "ARRAY") {
        item.callBack = async () => {
          let data = await getStatesAWS(company, state.stateArray);
          const { payload } = readDataAWS(data);
          setButtonStates(buttonMapping(payload.data));
        };
      }
      return item;
    });
  }
  };

  const handleDelete = async () => {
    pinInfo.pinStatus = "Delete";
    pinInfo.lastUpdate = new Date().toISOString();
    await deletePinAWS( pinInfo, "Delete", username, company);
    //scanner.feedbackSignal(2, 4);
   // closePopup();
   history.push("/")
  };

  const zoneButtonArray = () => {
    let buttons = [<></>];
    console.log("zones")

    for (let i = 0; i < zones.length; i++) {
      const element = zones[i];
      console.log(element)
      const button = (
        <span
          className={`button ${element.buttonClass}`}
          onClick={() => {element.callback(element.title)}}
        >
          {element.title}
        </span>
      );
     buttons.push(button);
    }
    console.log(buttons)
    return buttons;
  };

  const ListPlot = () => {
    if (pinInfo) {
      if (!moreInfo) {
        return (
          <>
            <h1>{company === "Leseman"?pinInfo.merk:pinInfo.brand}</h1>
            <h6>{`${pinInfo.model} ${pinInfo.type?pinInfo.type:""}`}</h6>

            <div class="blocks">
              <div class="block">
                <span class="block__title">Plaats</span>
                <span class="block__value">{pinInfo.placeName}</span>
              </div>
              <div class="block">
                <span class="block__title">Status</span>
                <span class="block__value">{pinInfo.pinStatus}</span>
              </div>
              <div class="block -wide">
                <span class="block__title">{UUID}</span>
                <span class="block__value">{pinInfo.chassis}</span>
                {            <span
              class="button -tertiary"
              data-model="history"
              onClick={onClickHistory}
            >
              <img src={historyIcon} alt="" />
              
            </span>}
            {     hasQRCode &&      <span
              class="button -tertiary"
              data-model="history"
              onClick={onClickQR}
            >
                              <FontAwesomeIcon icon={faQrcode} />

              
            </span>}
              </div>
            </div>
          </>
        );
      } else {
        let info = [];
        for (const property in pinInfo) {
          info.push(
            <tr>
              <td>{property}</td>
              <td>{pinInfo[property]}</td>
            </tr>
          );
        }
        return (
          <>
            <h1>{company === "Leseman"?pinInfo.merk:pinInfo.brand}</h1>
            <h6>{`${pinInfo.model} ${pinInfo.type}`}</h6>
            <table className="table">
              <tbody>{info}</tbody>
            </table>
          </>
        );
      }
    } else {
      return <p>Loading</p>;
    }
  };

  const getPin = async () => {
    let pos = { latitude: 0, longitude: 0 };
    try {
      //pos = await getLocation();
      console.log(pos);
    } catch (e) {
      pos = { latitude: 0, longitude: 0 };
      console.log(e);
    } finally {
      console.log(pos);
      return API.get("pinAPI", `/pin/${id}`, {
        queryStringParameters: {
          lat: pos.latitude,
          long: pos.longitude,
          company,
          username,
        },
      });
    }
  };

  const handleStateChange = async (state) => {
    await updatePin(state);
    setPinInfo((info) => {
      let copyInfo = {...info};
      copyInfo.pinStatus = state;
      return copyInfo;
    });
    setButtonStates(buttonMapping(defaultButtonStates));
    setShow(false);
  }

  const updatePin = async (pinStatus) => {
    let pos = { latitude: 0, longitude: 0 };
    try {
      pos = await getLocation();
    } catch (e) {
      pos = { latitude: 0, longitude: 0 };
      console.log(e);
    } finally {
      return API.put("pinAPI", `/pin/${id}`, {
        body: {
          company,
          username,
          pinStatus,
          chassis: pinInfo.chassis,
          lat: pos.latitude,
          long: pos.longitude,
        },
      });
    }
  };

  const getLocation = () => {
    return new Promise((resolve, reject) => {
      console.log(navigator.geolocation);
      if (navigator.geolocation) {
        console.log(navigator.geolocation);

        navigator.geolocation.getCurrentPosition(
          (pos) => {
            console.log(pos);
            resolve(pos.coords);
          },
          (e) => {
            reject("Timeout");
          },
          { timeout: 10000 }
        );
      } else {
        reject("Geolocation is not supported by this browser.");
      }
    });
  };

  const moreInfoButton = () => {
    setMoreInfo(!moreInfo);
  };


  const handleChangePlace = async (zone,zoneParking) => {

    if(typeof zone === 'string'){
      console.log("zone change place");
      console.log(zone);
      console.log(zoneParking);

      pinInfo.placeName = zone;
      if(zoneParking){
        pinInfo.parkingName =zoneParking;
      }
    }
    else{
      console.log("placeChange");
      console.log(newPlace);
      let editPlace = newPlace;
      if(newplaceFormatter[currentParking].postFormatter){
        for (let i = 0; i < newplaceFormatter[currentParking].postFormatter.length; i++) {
          const element = newplaceFormatter[currentParking].postFormatter[i];
          if(element.action === "ZERO_PAD"){
           let padsubstr = editPlace.substring(element.pos, element.pos+element.padLength);
            if(padsubstr.length < element.padLength){
              padsubstr = String(padsubstr).padStart(element.padLength, '0');
              editPlace = `${editPlace.substring(0,element.pos)}${padsubstr}`;
            }
          }

        }


      }
      console.log(editPlace);

      pinInfo.placeName = editPlace;

      pinInfo.parkingName = newplaceFormatter[currentParking].parking;

      console.log(pinInfo);
      if(newplaceFormatter[currentParking].parking){
        pinInfo.parkingName = newplaceFormatter[currentParking].parking;

        console.log(pinInfo);

      }
    }
    pinInfo.lastUpdate = new Date().toISOString();
console.log("Going to change place")
    await changePlaceAWS(company,pinInfo.chassis,pinInfo.pinID,pinInfo.placeName, username, pinInfo.parkingName  );
    setShowChangePlace(false);
    setNewPlace("");
    setCurrentParking(0);
  }


  const stateChangeButton = async (state) => {
    try {
      await updatePin(state);
    } catch (e) {
      console.log(e);
    } finally {
      handleClose();
    }
  };

  const buttonArray = () => {
    let buttons = [<></>];
    if(buttonStates){
    for (let i = 0; i < buttonStates.length; i++) {
      const element = buttonStates[i];
      console.log("BTNARAY")
      console.log(element.callback)
      const button = (
        <span
          class={`button ${element.buttonClass}`}
          onClick={element.callback?element.callback:()=>{}}

        >
          {element.title}
        </span>
      );
      buttons.push(button);
    }
  }
    return buttons;
  };

  const buttons = () => {
    return (
      <>
              <span
          class="button -open-model"
          data-model="status"
          onClick={handleShowChangePlace}
        >
          Wijzig plaats
        </span>
        <span
          class="button -open-model"
          data-model="status"
          onClick={handleShow}
        >
          Wijzig status
        </span>
        <a class="button -secondary" onClick={moreInfoButton}>
          {moreInfo ? "Minder " : "Meer "}Info
        </a>
      </>
    );
  };

  const getChangePlaceForm = () => {
    const placeFormatter = newplaceFormatter[currentParking];
    let formBuilder = [];
    if(placeFormatter.inputType === undefined){
      return <></>
    }
    else if (placeFormatter.inputType === "number" || placeFormatter.inputType === "string"){
      formBuilder.push(<input type={placeFormatter.inputType} min={placeFormatter.inputMin}  max={placeFormatter.inputMax} value={newPlace} onChange={(e) => {setNewPlace(e.target.value)}} placeholder="1.183"/>)
      if(placeFormatter.zones){
        formBuilder.push( <h3 class="model__title">Zones</h3>)
        let toggles = []
        for (let j = 0; j < placeFormatter.zones.length; j++) {
          toggles.push(<ToggleButton className={"button"} value={placeFormatter.zones[j]}>{placeFormatter.zones[j]}</ToggleButton>)
         }
         formBuilder.push(  <ToggleButtonGroup    value={newPlace} name={"zones"}  type="radio" size="lg" onChange={e => {
          console.log(e);
          let tog = {...togglePlace}
          handleChangePlace(e,newplaceFormatter[currentParking].parking);

          }}>
          {toggles}
        </ToggleButtonGroup>)
          formBuilder.push( <br />);
      }
    return <>{formBuilder}
   </>
  }
  else if(placeFormatter.inputType === "toggleGroup"){
    let formBuilder = [];
    for(let i=0; i < placeFormatter.toggles; i++){
      let el =  placeFormatter.toggleGroups[i];
      formBuilder.push( <h3 class="model__title">{el.title}</h3>)
      let toggles = [];
      for (let j = 0; j < el.values.length; j++) {
       toggles.push(<ToggleButton className={"button"} value={el.values[j]}>{el.values[j]}</ToggleButton>)
      }
      formBuilder.push(  <ToggleButtonGroup    value={togglePlace[el.title]} name={el.title} type="radio" size="lg" onChange={e => {
        console.log(e);
        let tog = {...togglePlace}
        tog[el.title] = e;
        setTogglePlace(tog);
        }}>
        {toggles}
      </ToggleButtonGroup>)

        //formBuilder.push( <br />);
    }
    if(placeFormatter.zones){
      formBuilder.push( <h3 class="model__title">Zones</h3>)
      let toggles = []
      for (let j = 0; j < placeFormatter.zones.length; j++) {
        toggles.push(<ToggleButton className={"button"} value={placeFormatter.zones[j]}>{placeFormatter.zones[j]}</ToggleButton>)
       }
       formBuilder.push(  <ToggleButtonGroup    value={newPlace} name={"zones"}  type="radio" size="lg" onChange={e => {
        console.log(e);
        let tog = {...togglePlace}
        handleChangePlace(e,newplaceFormatter[currentParking].parking);

        }}>
        {toggles}
      </ToggleButtonGroup>)
        formBuilder.push( <br />);
    }
    return formBuilder;

  }
  }


  const onClickUserDropdown = () => {
    if (dropdownClass === "dropdown__assign") setDrowndownClass("dropdown__assign -open");
    else setDrowndownClass("dropdown__assign");
  };

  const onClickUserSelector = (e) => {
    console.log(e.target.value);
    setDrowndownClass("dropdown__assign");
    setCurrentParking(e.target.value);
  };

  
  const onClickQR = () => {
    console.log(QRString);
    if(printer.type && printer.type.length > 1){
      setLabelQR(true);

    }
    setShowQR(!showQR);

  }

  return (
    <>
      <div class="container">
        {isError &&
        <h1>{errorText}</h1>}
        {!isError && ListPlot()}
        {pinInfo && buttons()}
        {pinInfo && hasQRCode  && <>          {showQR &&<>


<div className="QR_div">
   <QRCodeSVG value={QRString} />
  </div>
  { printer.type && printer.type.length > 1 && <div class="form__control toggle -close">
<input type="checkbox" id={1} defaultChecked={ labelQR===0?false:true} onChange={(e) => {setLabelQR(!labelQR)}}/>
<label for={1}>
    {labelQR?"label":"band"}
    <div class="toggle__switch">
        <span class="toggle__check"></span>
    </div>
</label>

</div>}
</>
} </>}
        {/* <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Kies Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>{buttonArray()}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal> */}
      </div>
     { pinInfo && <div
        className={`model -large ${showEvents ? "-active" : ""}`}
        data-model="history"
      >
        <div className="model__container">
          <h1>Historie {pinInfo.chassis}</h1>
          {/* <EventTable eventList={eventList} pin={scanData.chassis} /> */}
          <EventFlow eventList={eventList} pin={pinInfo.chassis} />

          <img
            src={crossIcon}
            alt=""
            className="model__cross"
            onClick={handleEventsClose}
          />
        </div>

      </div>}
      <div class={`model ${show ? "-active" : ""}`} data-model="status">
        <div class="model__container">
          <h2 class="model__title">Kies een status</h2>
          {buttonArray()}
          <img
            class="model__cross"
            src={crossLogo}
            onClick={() => setShow(false)}
          />
        </div>
      </div>
      <div class={`model ${showChangePlace ? "-active" : ""}`} data-model="status">
        <div class="model__container">
         { newplaceFormatter.length > 1 && <><h1>Parking</h1>
          <div
      className={dropdownClass}
      role="group"
      onClick={onClickUserDropdown }
      onChange={(e) => onClickUserSelector(e)}
    >
      {newplaceFormatter[currentParking].parking}
      <div className="dropdown__list__assign">
        {newplaceFormatter.map((parking,i) => {
          console.log("Dropdown" + i +"and" + currentParking)
          const isSelect = `${i}` === `${currentParking}`;
          console.log(isSelect)

          return (
            <div className="dropdown__option__assign">
              <input
                name="max"
                type="radio"
                value={i}
                id={i}
                checked={isSelect}
              />
              <label for={i}>{parking.parking}</label>
            </div>
          );
        })}
      </div>
      </div></>
}
          <h2 class="model__title">Voer Parkeerplaats in</h2>
          <div class={`form__control ${false ? "-error" : ""}`}>
            <label for="Plaats">Plaats</label>

            {getChangePlaceForm()}
            <span style={{ color: "red" }}>{placeValidationText}</span>
            <span style={{ color: "orange" }}>{placeWarningText}</span>
          </div>
          <span
          class={`button -open-model ${canSubmitNewPlace?"":"-disabled"}`}
          onClick={handleChangePlace}
        >Wijzig</span>        { zoneButtonArray()}

                 <img
            class="model__cross"
            src={crossLogo}
            onClick={() => setShowChangePlace(false)}
          />
        </div>
      </div>
    </>
  );
}
