

import React, { useState, useEffect } from "react";
import { useAppContext } from "../libs/contextLib";
import EventCard from "./EventCard"
import { getPictureAWS } from "../libs/lambdaLib";
import crossLogo from "./../img/icon-cross.svg";

const expectedFlow = ["Registered", "Carwash","Photos","Gate Out"]
export default function EventFlow(props) {
  // const [image, setImage] = useState(null);
  const [events, setEvents] = useState([]);
  const { company, defaultFlow } = useAppContext();

  const [showCard, setShowCard] = useState(false);
  const [event, setEvent] = useState([]);
  const [nextFlow, setNextFlow] = useState([]);
  const [image, setImage] = useState(undefined);
  const [eventID, setEventID] = useState(0);

  // const getPictureRow = async (timestamp, chassis) => {
  //   console.log(`${timestamp}-${chassis}`);
  //   let result = await getPictureAWS(chassis, timestamp, company);
  //   setImage(JSON.parse(result.Payload));
  // };

  const getPictureRow = async (timestamp, chassis) => {
    let result = await getPictureAWS(chassis, timestamp, company);
    console.log(JSON.parse(result.Payload));
    setImage(JSON.parse(result.Payload));
  };

  useEffect(() => {
    let eventsTemp = [];
    setShowCard(false);
    console.log(props);
    if (props.eventList && props.eventList.length>0) {
      for (let index = 0; index < props.eventList.length; index++) {
        let event = props.eventList[index];
        console.log(event);
        let eventDate = new Date(event.timestamp);
        event.day = eventDate.toLocaleDateString();
        event.time = eventDate.toLocaleTimeString();
        eventsTemp.push(event);
      }
     // const indexFlow = defaultFlow.findIndex((element) =>  element === props.eventList[props.eventList.length-1].pinStatus );
      //setNextFlow(defaultFlow.slice(indexFlow+1));
    }
    setEvents(eventsTemp);
  }, [props.eventList]);

  const onEventClick = (event) => {
    setEvent(events[event.currentTarget.dataset.id]);
    console.log(events[event.currentTarget.dataset.id]);
    setEventID(event.currentTarget.dataset.id);
    setShowCard(true);
    getPictureRow(events[event.currentTarget.dataset.id].timestamp,events[event.currentTarget.dataset.id].chassis)
  }

  const makeEventButtons = () => {
    let eventButtons = [];

    for (let i = nextFlow.length -1 ; i >= 0; i--) {
      eventButtons.push(<li className="step0" >{nextFlow[i]}</li>)

  }

    for (let i = 0; i < events.length; i++) {
        eventButtons.push(<li onClick={onEventClick} className="active step0"  data-id={i}  >
        <table class="table -minimal -small">
          <tr>
              <td>{events[i].day} {events[i].time}</td>
            </tr>
            <tr>
              <td>{events[i].pinStatus}</td>
            </tr>
          </table></li>)

    }



    return eventButtons

  }

  return (
    <>
      <ul className="progressbar">
        {makeEventButtons()}
      </ul>
        <div
        className={`model -large ${showCard ? "-active" : ""}`}
        data-model="history"
      >
            <div class="event__card" style={{top: `${eventID*102+20}px`}}>
              {/* <div className="event__card__table"> */}
              <EventCard event={event} />
              {/* </div> */}
                {image &&                   <img   onError={() => { setImage(undefined)}}  className="event__card__image"  src={image} rounded />
}
<img
            class="model__cross"
            src={crossLogo}
            onClick={() => setShowCard(false)}
          />      
            </div></div>
    </>
  );
}
