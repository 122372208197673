const config = {
  apiGateway: {
    REGION: "eu-central-1",
    URL: "https://b5boebcr0j.execute-api.eu-central-1.amazonaws.com/single",
  },
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: "eu-central-1_dNSQXaEQY",
    APP_CLIENT_ID: "6tsrhprinsvmiit16v0i5kdg5b",
    IDENTITY_POOL_ID: "eu-central-1:a2ffcc19-6b3c-4f37-90db-c723216ae152",
  },
};

export default config;
